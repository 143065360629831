import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.carousel-header');
  const slides = document.querySelectorAll('.carousel-header__slide');

  if (1 < slides.length) {
    new Swiper(carousel, {
      slidesPerView: 1,
      loop: true,
      navigation: {
        prevEl: carousel.querySelector('.swiper-button-prev'),
        nextEl: carousel.querySelector('.swiper-button-next'),
      },
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        type: 'fraction',
        formatFractionCurrent: (number) => {
          if (10 > number) {
            return '0' + number;
          }
          return number;
        },
        formatFractionTotal: (number) => {
          if (10 > number) {
            return '0' + number;
          }
          return number;
        },
      },
    });
  }
});
